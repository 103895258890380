<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">{{ translations.tcVisitNoteDetails }}</h1>
    </page-header>

    <page-body>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm mb-3">{{ translations.tcVisitNoteDetails }}</h1>
        <div class="d-flex mb-2">
          <i class="mr-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="29"
              viewBox="0 0 18 29"
            >
              <path
                fill="#003946"
                d="M19.4133553,126.182446 L13.0210995,139.828758 C12.8020299,140.288119 12.1317422,141.613705 11.6249393,142.617737 C11.5052697,142.852379 11.2647285,143 11.0020622,143 C10.7393958,143 10.4988547,142.852379 10.3791851,142.617737 C9.87238217,141.613705 9.20863387,140.288119 8.99937332,139.828758 L2.58422961,126.182446 C2.36521091,125.704643 2.21229977,125.199041 2.12974186,124.679678 C1.24692392,119.479052 4.97110775,114.485138 10.2058912,114.03562 C12.7204458,113.810842 15.2133359,114.657196 17.0753738,116.367861 C18.9374118,118.078525 19.997056,120.495902 19.9953612,123.029258 C20.0336111,124.110394 19.8349351,125.18678 19.4133553,126.182446 Z M11.7622665,118.580016 C9.83085988,118.246591 7.90655963,119.206127 7.00487413,120.95225 C6.10318862,122.698374 6.43157222,124.829346 7.81672751,126.220592 C9.20188279,127.611838 11.3251216,127.943269 13.0659499,127.039979 C14.8067782,126.136689 15.7646769,124.206501 15.4341352,122.268031 C15.1107327,120.383063 13.6403645,118.906229 11.7622665,118.580016 L11.7622665,118.580016 Z"
                transform="translate(-2 -114)"
              />
            </svg>
          </i>
          <h6>{{ location.org_name }}</h6>
        </div>
        <div class="mb-3 address">
          {{ location.address_line_1 }}
          <br v-if="location.address_line_1" />
          {{ location.address_line_2 }}
          <br v-if="location.address_line_2" />
          {{ location.city }}
          <span v-if="location.city && location.state">, </span
          >{{ location.state }} {{ location.zip }}
          <br />
          {{ location.phone_number_formatted }}
          <span v-if="location.email && location.phone_number_formatted"
            >|</span
          >
          <a :href="'mailto:' + location.email">{{ location.email }}</a>
        </div>
        <div class="d-flex mb-3">
          <i class="mr-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
            >
              <path
                fill="#003946"
                d="M26.6359012,13 C26.3533291,13 26.1242733,13.2238575 26.1242733,13.5 L26.1242733,17.75 C26.1242733,18.02614 26.3533291,18.25 26.6359012,18.25 C26.9184733,18.25 27.1475291,18.02614 27.1475291,17.75 L27.1475291,13.5 C27.1475291,13.2238575 26.9184733,13 26.6359012,13 Z M37.3800872,13 C37.0975151,13 36.8684593,13.2238575 36.8684593,13.5 L36.8684593,17.75 C36.8684593,18.02614 37.0975151,18.25 37.3800872,18.25 C37.6626593,18.25 37.8917151,18.02614 37.8917151,17.75 L37.8917151,13.5 C37.8917151,13.2238575 37.6626593,13 37.3800872,13 Z M23.0545058,14.5 C21.9172081,14.5 21,15.39636 21,16.5078125 L21,21 L43,21 L43,16.5078125 C43,15.39636 42.0827663,14.5 40.9454942,14.5 L39.4186047,14.5 L38.403343,14.5 L38.403343,16.6328125 C38.7171219,16.9074625 38.9149709,17.3059375 38.9149709,17.75 C38.9149709,18.5784275 38.2277805,19.25 37.3800872,19.25 C36.532394,19.25 35.8452035,18.5784275 35.8452035,17.75 C35.8452035,17.3059375 36.0430526,16.9074625 36.3568314,16.6328125 L36.3568314,14.5 L27.6511628,14.5 L27.6511628,16.625 C27.9698251,16.899855 28.1707849,17.30198 28.1707849,17.75 C28.1707849,18.5784275 27.4835944,19.25 26.6359012,19.25 C25.7882079,19.25 25.1010174,18.5784275 25.1010174,17.75 C25.1010174,17.3098975 25.2957328,16.9150175 25.6046512,16.640625 L25.6046512,14.5 L23.0545058,14.5 Z M21,22 L21,32.992175 C21,34.103625 21.9171826,35 23.0545058,35 L40.9454942,35 C42.0827663,35 43,34.103625 43,32.992175 L43,22 L21,22 Z"
                transform="translate(-21 -13)"
              />
            </svg>
          </i>
          <p>{{ displayDate }}</p>
        </div>
        <section class="section-4 bg-block mb-4">
          <div class="body" v-if="Section3">
            <div class="presentation-details">
              <div class="font-weight-bold">
                <span class="label">{{ translations.tcContactedBy }}:</span>
                <span class="value">{{ userSelectedNote.name }}</span>
              </div>
              <div class="font-weight-bold">
                <span class="label"></span>
                <span class="value"></span>
              </div>
              <div class="font-weight-bold">
                <span class="label">{{ translations.tcNoteType }}:</span>
                <span class="value">{{ contactTypeTranslated }}</span>
              </div>
            </div>
          </div>
        </section>
        <section class="section-4 bg-block mb-4">
          <div class="body">
            <div class="presentation-details">
              <div class="font-weight-bold">
                <span class="label">{{ translations.tcNotes }}:</span>
                <span class="value keep-breaks">{{ userSelectedNote.nte_note }}</span>
              </div>
            </div>
          </div>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-5">
          <div class="body">
            <div class="d-flex action-buttons">
              <b-button
                variant="primary"
                v-if="iCanSeeAny(secured_note_edit_controls)"
                @click="handleEditButtonClick"
                class="flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0"
                >{{ translations.tcEdit }}</b-button
              >
              <b-button
                variant="tertiary"
                @click="handleCancelButtonClick"
                class="flex-0 mr-0 mr-sm-3 w-100-sd"
                >{{ translations.tcCancel }}</b-button
              >
              <b-button
                variant="secondary"
                v-if="iCanSeeAny(secured_note_delete_controls)"
                @click="handleDeleteButtonClick"
                class="flex-0 mr-0 mr-sm-3 w-100-sd"
                >{{ translations.tcDelete }}</b-button
              >
            </div>
          </div>
        </section>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'view-location-visit',
  mixins: [translationMixin],
  data() {
    return {
      contactTypeTranslated: '',
      Section3: true,
      secured_note_delete_controls: {
        delete_scripture_placement_facility_notes_button:
          'ca8dae12-66fa-45f2-b329-1b79c661f577',
        delete_aux_facility_note_button: '24e805f5-106f-49e7-8ec3-087260fe09b6',
      },
      secured_note_edit_controls: {
        edit_scripture_placement_facility_notes_button:
          '5d19b1a1-9c43-4738-83d4-278ce9edf663',
        edit_aux_facility_note_button: 'fbd3819d-d374-44de-917c-2d80663af107',
      },
      toggle: true,
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedNote: 'user/setSelectedNote',
      saveProspectNote: 'membership/saveProspectNote',
    }),
    async handleEditButtonClick() {
      this.$router.push({ name: 'location-add-visit' })
    },
    async handleCancelButtonClick() {
      await this.setSelectedNote({})
      this.$router.go(-1)
    },
    async handleDeleteButtonClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcVisitWillBeDeletedOk,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel,
      }).then((result) => {
        if (result.value) {
          let note = {
            nte_entity_key: this.userSelectedLocationKey,
            nte_contact_by_ind_key: this.userSelectedNote
              .nte_contact_by_ind_key,
            nte_note: this.userSelectedNote.nte_note,
            nte_contact_date: this.userSelectedNote.contact_date,
            nte_ctt_key: this.userSelectedNote.nte_ctt_key,
            nte_add_user: this.userId,
            nte_delete_flag: true,
            nte_key: this.userSelectedNote.nte_key,
            // Subject is hard coded to match the old version of theConnection
            nte_subject: 'Church Contact Notes',
          }
          this.saveNote(note)
        }
      })
    },
    async saveNote(note) {
      let result = true
      await Promise.all([
        this.setLoadingStatus(true),
        (result = await this.saveProspectNote(note)),
      ]).then(() => {
        this.setLoadingStatus(false)
        this.$swal({
          icon: result ? 'success' : 'error',
          text: result ? this.translations.tcVisitDeleted : this.translations.tcErrorDuringSave,
          confirmButtonText: this.translations.tcOk,
        }).then((result) => {
          if (result.value) {
            this.setSelectedNote({})
            this.$router.push({ name: 'location-visits' })
          }
        })
      })
    },
    updateDynamicText() {
      if (!this.translations.common || !this.userSelectedNote) return false

      const contactTypeGuid = this.userSelectedNote.nte_ctt_key
      return this.contactTypeTranslated = this.translations.common['placement-facility-note-types'].hasOwnProperty(contactTypeGuid) ?
        this.translations.common['placement-facility-note-types'][contactTypeGuid] :
        this.userSelectedNote.contact_type
    },
  },
  async created() {
    if (!this.userSelectedLocationKey || !this.userSelectedNote) {
      this.$router.push({ name: 'scripture-distribution' })
    }

    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('common.placement-facility-note-types')
      ]).then(results => {
        this.stripReadableText(results[1])
        const translatedText = { common: { ...this.translations.common } }
        this.$set(this.translations, 'components', translatedText)

        this.updateDynamicText()
      })
    } catch (e) {
      console.error('Error in LocationVisitDetails.vue, created(), ', e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
      location: 'scriptureDistribution/locationDetail',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userSelectedNote: 'user/userSelectedNote',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
    }),
    displayDate() {
      let cDate = !!this.userSelectedNote.contact_date
        ? new Date(this.userSelectedNote.contact_date)
        : null
      let month = !!cDate ? cDate.toLocaleString(this.prefCulture, { month: 'long' }) : ''
      let dayOfWeek = !!cDate ? cDate.toLocaleString(this.prefCulture, { weekday: 'long' }) : ''
      let day = !!cDate ? cDate.getDate() : ''
      let year = !!cDate ? cDate.getFullYear() : ''

      return `${dayOfWeek}, ${month} ${day} ${year}`
    },
  },
  components: {
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
  .keep-breaks {
    white-space: pre-wrap;
  }
</style>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.page-body {
  padding: 80px 15px;
}

.bg-block {
  padding: 34px 32px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.section-1 {
  .d-flex {
    align-items: center;
  }
  i {
    width: 22px;
    font-size: 0;
    text-align: center;
    svg {
      display: inline-block;
    }
  }
  h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
  .address {
    padding-left: 36px;
  }
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.presentation-specs,
.presentation-details {
  max-width: 680px;
  column-gap: 40px;
  margin-bottom: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid #979797;
  @include breakpoint(sm) {
    columns: 1;
    column-gap: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  div {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    span {
      display: block;
      color: #636363;
      font-weight: 700;
      line-height: 22px;
    }
    .label {
      display: block;
      margin-right: 0.25rem;
      text-transform: uppercase;
    }
  }
}

.section-5 {
  .d-flex {
    button {
      min-width: 280px;
    }

    &.action-buttons {
      button {
        min-width: inherit;
      }
    }
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
